export default [{
    header: 'Blogs',
    icon: 'TrelloIcon',
    children: [{
            title: 'Lista',
            route: 'blogs',
            icon: 'ListIcon'
        },
      /*   {
            title: 'Agregar',
            route: 'blogs-create',
            icon: 'MousePointerIcon'
        } */
    ]
}];