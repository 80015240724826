export default [{
    header: 'Productos',
    icon: 'ShoppingBagIcon',
    children: [{
        title: 'Lista',
        route: 'products',
        icon: 'ListIcon'
    }, {
        title: 'Comentarios',
        route: 'product-comments',
        icon: 'MessageCircleIcon'
    }]
}];