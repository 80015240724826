import home from './home';
import product from './product';
import discountcoupon from './discountcoupon';
import contact from './contact';
import sale from './sale';
import subscriber from './subscriber';
import blog from './blog';

export default [
    ...home,
    ...product,
    ...discountcoupon,
    ...contact,
    ...sale,
    ...subscriber,
    ...blog
];